@font-face {
  font-family: 'Flowfont Title';
  src: url('../../public/assets/fonts/Bangers-Regular.ttf') format('woff2'),
    url('../../public/assets/fonts/Bangers-Regular.ttf') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'Flowfont Bold';
  src: url('../../public/assets/fonts/Nunito-Black.woff2') format('woff2'),
    url('../../public/assets/fonts/Nunito-Black.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flowfont Medium';
  src: url('../../public/assets/fonts/Nunito-Bold.woff2') format('woff2'),
    url('../../public/assets/fonts/Nunito-Bold.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flowfont Regular';
  src: url('../../public/assets/fonts/Nunito-SemiBold.woff2') format('woff2'),
    url('../../public/assets/fonts/Nunito-SemiBold.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flowfont Thin';
  src: url('../../public/assets/fonts/Nunito-Regular.woff2') format('woff2'),
    url('../../public/assets/fonts/Nunito-Regular.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
