@import 'styles/_custom-variables.scss';

.Select {
  &__control {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 16px !important;
    background-color: transparent !important;
    color: var(--neutral) !important;
    border: solid 2px var(--neutralVerySubtle) !important;

    &--is-focused {
      //color: yellow !important;
      //border-color: var(--neutral) !important;
      outline: 0;
      box-shadow: none !important;
    }
  }

  &__single-value {
    color: var(--neutral) !important;
  }

  &__placeholder {
    font-family: $flowfont;
    padding: 0 5px;
  }

  &__menu {
    position: absolute;
    z-index: 100 !important;
    &-list {
      background-color: var(--halfDistant);
      color: var(--neutral);
    }
  }

  &__option {
    &--is-selected,
    &--is-focused,
    &:hover {
      color: var(--white) !important;
      background-color: var(--secondary);
    }
  }

  &__multi-value {
    background-color: var(--secondary) !important;
  }

  &__multi-value__label {
    color: var(--white) !important;
  }
  &__multi-value__remove {
    color: var(--white) !important;
  }
}
